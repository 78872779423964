<template>
    <div>
        <template v-if="item.deleted_at">
            <span v-if="type === 'id'">{{ item.id }}</span>
            <span v-if="type === 'name'">{{ item.name }}</span>
        </template>
        <router-link
            v-else
            :to="{name: 'dealer', params: { dealer_id: type === 'dealer' ? item.dealer_id : item.id }}">
            <span v-if="type === 'id'">{{ item.id }}</span>
            <span v-if="type === 'name'">{{ item.name }}</span>
            <span v-if="type === 'dealer'">{{ item.dealer_name }}</span>
        </router-link>
    </div>
</template>

<script>

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    }
};
</script>
